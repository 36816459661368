import React from "react";
import Layout from "../components/Layout";

const page = (data) => {
  return (
    <Layout>
      <h1>Slug: {data.pageContext.slug}</h1>
      <p>id: {data.pageContext.id}</p>
    </Layout>
  );
};

export default page;
